import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navigation from "@components/navigation";
import { lazy, Suspense } from "react";

const CV = lazy(() => import("@components/cv"));
const Home = lazy(() => import("@components/home"));
const Footer = lazy(() => import("@components/footer"));

function App() {
  return (
    <BrowserRouter>
      <div className="flex flex-row justify-around w-screen">
        <div className="flex flex-col w-2/3 min-h-screen align-middle justify-between">
          <Navigation />
          <main>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/cv" element={<CV />} />
              </Routes>
            </Suspense>
          </main>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
