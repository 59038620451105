import React from "react";
import { Link } from "react-router-dom";

// Navigation component sticky  to the top of the page
const Navigation: React.FC = () => {
  const links = [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "CV",
      to: "/cv",
    },
  ];
  return (
    <>
      <div className="bg-gray-200 dark:bg-black sticky top-0 z-10">
        <div className="flex text-gray-900 dark:text-gray-100 flex-row h-16 justify-between">
          <Link
            key={"VG"}
            className="p-5 text-2xl font-bold rounded-lg shadow-none transition-shadow duration-300 cursor-pointer hover:shadow-lg hover:shadow-gray-500"
            to={"/"}
          >
            {"Volkan Gülen"}
          </Link>
          <nav className="my-auto text-gray-900 dark:text-gray-100">
            {links.map((link) => (
              <Link
                key={link.to}
                className="p-5 rounded-lg shadow-none transition-shadow duration-300 cursor-pointer hover:shadow-lg hover:shadow-gray-500"
                to={link.to}
              >
                {link.text}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
};

export default Navigation;
